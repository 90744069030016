/* Estilos para el Carousel
.carousel-container {
    width: 100%;
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  .carousel {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
  }
  

  .carousel img {
    max-width: 100%;
    height: auto;
  }

  .carousel-thumbs {
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .thumb{
    width: 8% !important;
  }

  @media (max-width: 768px) {
    .thumb{
      width: 30% !important;
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    .thumb{
      width: 20% !important;
    }
    
  }


  @media (min-width: 1025px) and (max-width: 2560px) {
    .thumb{
      width: 12% !important;
    }
    
  } */



  /* se comenta */
  .banner-principal {
    max-width: 100%;
    max-height: calc(100vh - 8rem); 
    width: 100%;
    height: auto;
  }

  /* .gallery-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
 
  } */
  
  .carousel {
    /* flex: 10;
    margin-right: 20px; */
  }
  
  .carousel img {
    max-width: 100%;
    height: auto;
  }
  
  .thumbnails {
    /* flex: 2; */
    display: flex;
    /* flex-direction: column;
    align-items: flex-start; */
  }

    /* .thumbnails .img {
        width: 300px;
        height: 160px;
        margin-bottom: 10px;
        cursor: pointer;
        position: relative;
    } */

    /* .thumbnails .selected {
        border: 2px solid #007bff;
    } */
  
  @media (max-width: 768px) { 
    /* .thumbnails {
      flex-direction: column; 
      align-items: center; 
      justify-content: center; 
    } */
  
    .thumbnails img {
      width: 70px; 
      height: auto;
      margin: 5px; 
    }
  }